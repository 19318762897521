<template>
  <base-section id="404">
    <base-img
      :src="require('@/assets/mullshad.png')"
      class="mx-auto mb-12"
      contain
      width="50%"
    />
    <base-heading title="404.... :(" />
    <base-subheading
      space="8"
      title="Looks like this page doesn't exist"
    />

    <div class="text-center">
      <base-btn :to="{ name: 'Home' }">
        Home Page
      </base-btn>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'FourOhFour',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
